import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTopBanner = styled(Box)(
    ({ theme: { breakpoints, palette, spacing } }) => ({
        minWidth: '100%',
        backgroundColor: palette.grey[200],
        padding: spacing(15),
        height: 500,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '90%',
        [breakpoints.down('md')]: {
            backgroundImage: 'none',
            padding: spacing(5),
            height: 300,
        },
    })
);

function TopBanner(props) {
    const { bannerImg, children } = props;
    return (
        <StyledTopBanner sx={{ backgroundImage: `url(${bannerImg})` }}>
            {children}
        </StyledTopBanner>
    );
}

export default TopBanner;
