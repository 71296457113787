import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import {
    faCheckCircle,
    faNoteSticky,
    faRectangleList,
} from '@fortawesome/free-regular-svg-icons';

import BulletList from '../components/BulletList';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import TopBanner from '../components/TopBanner';
import bannerImg from '../images/course-banner.png';

import { Box, Button, Container, Typography } from '@mui/material/';

function PmCourse() {
    return (
        <Layout>
            <TopBanner bannerImg={bannerImg}>
                <Typography
                    color="primary"
                    variant="h3"
                    component="h1"
                    maxWidth={600}
                >
                    Product Management Fundamentals Certification Course
                </Typography>
                <Typography variant="h5" my={3} maxWidth={600}>
                    A completely free course developed for aspiring Product
                    Managers
                </Typography>
            </TopBanner>

            <Container maxWidth="lg" sx={{ mt: 7, px: 4 }}>
                <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={{
                        xs: 'column-reverse',
                        md: 'row',
                    }}
                >
                    <Box
                        mr={{ md: 4 }}
                        mt={{ sm: 3, md: 0 }}
                        maxWidth={{ md: 400 }}
                    >
                        <Typography variant="h4" mb={4} color="primary">
                            Part 1: Course Material
                        </Typography>
                        <BulletList
                            listItems={[
                                '100% online and self paced course',
                                'Developed for beginners by industry veterans',
                                'Over forty chapters covering all product management fundamentals',
                                'More than 30 concepts including prioritization frameworks, building a MVP, product roadmaps, product metrics and much more',
                                'Walkthrough of concepts using examples',
                            ]}
                            icon={faNoteSticky}
                        />
                    </Box>
                    <Box height={{ sm: '100%', md: 400 }} width="100%">
                        <iframe
                            src="https://www.youtube.com/embed/3cHNFwLd51o"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        />
                    </Box>
                </Box>
            </Container>

            <Box bgcolor="grey.100" my={5}>
                <Container maxWidth="lg" sx={{ p: 4 }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection={{
                            xs: 'column',
                            md: 'row',
                        }}
                    >
                        <StaticImage
                            src="../images/quiz.png"
                            alt="Product Management Fundamentals Certification Course"
                            loading="eager"
                            width={500}
                        />
                        <div>
                            <Typography variant="h4" color="primary">
                                Part 2: Quiz
                            </Typography>
                            <BulletList
                                listItems={[
                                    'An extensive 100 questions multiple choice quiz',
                                    'Review and assess your understanding of product management fundamentals',
                                    'All questions in the quiz are from the course material',
                                ]}
                                icon={faCheckCircle}
                            />
                            <Button
                                size="large"
                                fullWidth
                                sx={{ mt: 5 }}
                                variant="contained"
                                component="a"
                                href="https://forms.gle/Gw2yhFLzB7fD9DEDA"
                                target="_blank"
                            >
                                Take the quiz
                            </Button>
                        </div>
                    </Box>
                </Container>
            </Box>

            <Container
                id="assignment"
                maxWidth="lg"
                sx={{
                    mt: 5,
                    px: 4,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={{
                        xs: 'column',
                        md: 'row',
                    }}
                >
                    <Box mr={4}>
                        <Typography
                            variant="h4"
                            mb={4}
                            color="primary"
                            id={'assignment'}
                        >
                            Part 3: Take home assignment
                        </Typography>

                        <BulletList
                            listItems={[
                                'Modelled on actual take home PM assigments',
                                'Apply your learnings in a real life scenario',
                                'Receive personalized feedback from expert Product Managers',
                            ]}
                            icon={faRectangleList}
                        />
                        <Button
                            size="large"
                            fullWidth
                            sx={{ mt: 5 }}
                            variant="contained"
                            component="a"
                            href="https://drive.google.com/file/d/165TecOOH_TpECSEJ7iR9BUmFo3aue9at/view?usp=sharing"
                        >
                            Download assignment instructions
                        </Button>
                    </Box>
                    <StaticImage
                        src="../images/assignment.png"
                        alt="Product Management Fundamentals Certification Course"
                        loading="eager"
                        height={400}
                    />
                </Box>
            </Container>

            <Footer />
        </Layout>
    );
}

export default PmCourse;

export function Head() {
    return (
        <Seo
            title="Product Management Fundamentals Certification Course"
            description="A free online certification course for product managers covering the fundamentals of product management. It includes a multiple choice quiz and a Product Manager take home assignment.This course has been developed for MBA students without product management experience, a software engineer looking to transition into product management, Anyone who is curious about product management or Looking for a refresher."
        />
    );
}
